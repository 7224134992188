<template>
  <div ref="map"></div>
</template>

<script>
  /* eslint-disable */
  const RichMarker = require('googlemaps-js-rich-marker').default;
  import { Loader } from 'google-maps';
  import { mapState } from 'vuex';

  export default {
    name: 'map-component',
    props: {
      points: {
        type: Array,
        default: [],
      },
    },
    data() {
      return {
        map: null,
        styledMapType: null,
        markers: [],
        // indicates that google maps is currently loading. It should not be loaded again.
        isLoading: false,
      };
    },
    async mounted() {
      await this.getMap();
      await this.setMarkers();
      setTimeout(async () => {
        await this.setMarkers();
      }, 500);
    },
    computed: {
      ...mapState('GoogleMapsLoaderStore', ['isGoogleMapsLoaded']),
    },
    watch: {
      async points() {
        await this.clearMarkers();
        await this.setMarkers();
      },
    },
    methods: {
      async loadGoogleMaps() {
        if (this.isGoogleMapsLoaded) return;
        if (this.isLoading) {
          // This is a second or subsequent request for google maps. It should not be loaded again.
          await this.sleep(1000);
          return;
        }
        this.isLoading = true;
        if (window.google != null) {
          console.warn('window.google was not null. Marking google maps api as loaded.');
          return;
        }
        const options = {
          version: 'quarterly',
        };
        const loader = new Loader(vuexStore.state.config.obj_texts.MapApiKey, options);
        console.log('Loading google maps.');
        window.google = await loader.load();
        await this.sleep(500);
        this.$store.commit('GoogleMapsLoaderStore/markGoogleMapsLoaded');
        this.isLoading = false;
      },
      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      removeGoogleMaps() {
        window.google = {};
      },
      async getMap() {
        const styles = [
          {
            stylers: [{ saturation: -50 }],
          },
          {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [{ /*  "color": "#34495e" */ }],
          },
          {
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry.fill',
            stylers: [{ /* "color": "#16a085" */ }],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels',
            stylers: [{ visibility: 'on' }],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.text',
            stylers: [{ visibility: 'on' }],
          },
          {
            featureType: 'road.local',
            elementType: 'labels.text',
            stylers: [{ visibility: 'on' }],
          },
          {},
        ];

        await this.loadGoogleMaps();

        const options = {
          mapTypeControlOptions: {
            mapTypeIds: ['Styled'],
          },
          center: new google.maps.LatLng(56.159082, 10.210045),
          zoom: 2,
          mapTypeId: 'Styled',
          icon: '/Gfx/MapArrow.png',
          disableDefaultUI: true,
          zoomControl: true,
          scaleControl: false,

        };

        this.map = new google.maps.Map(this.$refs.map, options);
        await this.$nextTick;
        this.styledMapType = new google.maps.StyledMapType(styles, { name: 'Styled' });
      },
      async clearMarkers() {
        await this.loadGoogleMaps();
        for(const marker of this.markers) {
          marker.setMap(null);
        }
        this.markers = [];
      },
      async setMarkers() {
        await this.loadGoogleMaps();

        $('.MapMarker').parent().parent().remove();
        $('.MapMarker').remove();

        const coordinateList = [];
        for (const point of this.points) {
          const latLng = new google.maps.LatLng(parseFloat(point.latitude), parseFloat(point.longitude));
          coordinateList.push(latLng);
          this.markers.push(new RichMarker.RichMarker({
            map: this.map,
            position: latLng,
            draggable: false,
            flat: true,
            content: `<span class='mapComponentMarker ${point.style || 'normal'}' style="height:20px;width:20px;"></span>`,
          }));
        }

        //  Create a new viewpoint bound
        const bounds = new google.maps.LatLngBounds();

        if (this.points.length === 0) {
          // Koordinater til at indramme et kort over Danmark
          coordinateList.push(new google.maps.LatLng(57.725969, 8.267212));
          coordinateList.push(new google.maps.LatLng(54.563901, 11.892700));
        }

        for (const coordinate of coordinateList) {
          bounds.extend(coordinate);
        }
        if (!this.map) {
          return;
        }
        this.map.fitBounds(bounds);

        this.map.mapTypes.set('Styled', this.styledMapType);
        if (this.points.length === 1) {
          this.map.setZoom(15);
        }

        this.map.setOptions({ scrollwheel: false });
        this.map.setOptions({ draggable: true });
      },
    },
  };
</script>

<style lang="scss">
  .mapComponentMarker {
    display: flex;
    width: 10px;
    height: 10px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 700;
    color: #ffffff;
    background: #6f6f6f;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.07);

    &.normal {
      background: var(--ThemeMapMarkerColor) !important;
    }

    &.alert {
      background: var(--ThemeAlertColor) !important;
    }
  }
</style>
